.login-wrapper {
    padding: 0rem 3rem;
    background-color: white;
    color: black;
    height: calc(100dvh - 52px);
    line-height: 1.5;
    margin-top: 52px;
    overflow: hidden;
}

.logo-wrapper {
    text-align: center;
    margin-top: 30%;
    padding-bottom: 2rem;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input {
    width: 40%;
    margin-bottom: 10px;
}

.signin-btn {
    margin-top: 2rem;
}

.signup-btn {
    margin-top: 0.5rem;
}
