.collection-list-container {
    display: flex;
    flex-direction: column;
    gap: 0.3em;
}

.collection-list-container a {
    text-decoration: none;
}

.collection-list-container .collection-list-item {
    display: flex;
    flex-direction: row;
    background: #f9f9f9;
    border: thin solid #e0e4e9;
    border-left: 0;
    border-right: 0;
    cursor: pointer;
}

.collection-list-container .collection-list-item > div {
    width: 50%;
}

.collection-list-container .collection-list-item .collection-item-picture img {
    width: 100%;
    height: 100%;
}

.collection-list-container .collection-list-item .collection-item-header {
    display: flex;
    flex-direction: column;
}

.collection-list-container .collection-list-item .collection-item-header h3 {
    text-align: left;
    margin: 0;
    font-size: 12px;
    text-decoration: none;
    padding: 0px 10px;
    margin-top: 0.5em;
}

.collection-list-container
    .collection-list-item
    .collection-item-header
    .author-name {
    text-align: left;
    margin: 0;
    font-size: 10px;
    text-decoration: none;
    padding: 0px 10px;
}

.collection-list-container
    .collection-list-item
    .collection-item-header
    .collection-details-bullets {
    display: flex;
    flex-direction: row;
    grid-column-gap: 6px;
    column-gap: 6px;
    font-size: 9px;
    padding: 0px 10px;
    margin-top: 0.5em;
}

.collection-list-container
    .collection-list-item
    .collection-item-header
    .collection-details-bullets
    > span {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
}
