.collection-information-wrapper {
    .image-wrapper {
        display: flex;
        position: relative;
        img {
            width: 100%;
        }

        // .play-intro-btn {
        //     position: absolute;
        //     right: 5px;
        //     bottom: 5px;
        //     display: flex;
        //     align-items: center;
        //     background: #444343d6;
        //     color: white;
        //     box-shadow: 0px 0px 6px 0px #ffffff;
        //     border-color: #6f6f6f;

        //     > span {
        //         color: #ffffff;
        //     }
        // }
    }

    .details-wrapper {
        padding: 12px;
        background-color: white;
        z-index: 0;

        .collection-name {
            font-weight: bold;
            font-size: 1rem;
            line-height: 1;
            text-align: left;
            color: #000000;
            padding: 0;
            margin: 0;
        }

        .collection-author {
            font-size: 0.75rem;
            line-height: 2;
            margin: 0;
            color: #444343;
            font-weight: normal;
        }

        .collection-details-bullets {
            display: flex;
            flex-direction: row;
            grid-column-gap: 6px;
            column-gap: 6px;
            font-size: 10px;
            color: #444343;
            font-weight: normal;
            > span {
                display: flex;
                flex-direction: row;
                column-gap: 12px;
                align-items: center;
            }
        }
    }
}

.button-set {
    button {
        &.active {
            background-color: lightgray;
        }
    }
}

.collection-module {
    border: thin solid #e0e4e9;
    background: #f9f9f9;
    margin-bottom: 0.5rem;
}

.collection-header {
    text-align: center;
    justify-content: center;
    align-items: center;
}

.myCollection-header-combined {
    justify-content: center;
    align-items: center;
    align-items: center;
    padding: 1rem;
    display: flex;
}

.module-list {
    position: relative;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
}

.module-img {
    max-width: 20%;
    max-height: 20%;
}

.backButton {
    position: fixed;
    top: 3%;
    left: 3%;
}

.button-group {
    position: static;
    margin: 0;
    text-align: center;
    flex-direction: column;
    display: block;
    flex-direction: column;
    height: auto;
    flex-shrink: 0;
    padding: 12px;
}

.videoThumbnail {
    position: static;
    position: static;
    margin: auto;
    display: block;
    flex-shrink: 0;
}

.button-item {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    list-style: none;
}

.collection-details-modal-wrapper {
    height: calc(100dvh - 7rem);
    .collection-name {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1;
        text-align: left;
        color: #000000;
        padding: 0;
        margin: 0;
    }

    .collection-author {
        font-size: 1rem;
        line-height: 2;
        margin: 0;
        color: #444343;
        font-weight: normal;
    }

    .collection-details-bullets {
        display: flex;
        flex-direction: row;
        grid-column-gap: 6px;
        column-gap: 6px;
        font-size: 12px;
        color: #444343;
        font-weight: normal;
        margin-bottom: 12px;
        > span {
            display: flex;
            flex-direction: row;
            column-gap: 12px;
            align-items: center;
        }
    }
}

.issuer * {
    color: #3fa2ff;
}
