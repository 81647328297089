@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");

body,
html {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#root,
.App {
    font-family: "Inter", sans-serif;
    height: 100%;
    display: flex;
    flex-direction: column;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    z-index: 100;
}

.page {
    margin-top: 56px;
    flex: 1 1;
    overflow-y: auto;
    overflow-x: hidden;
    &.search-bar-open {
        margin-top: 112px;
    }
}

.footer {
    left: 0;
    flex-shrink: 0;
    background-color: #ffffff;
    width: 100%;
    padding: 16px 10px;
    box-sizing: border-box;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-top: 1px solid #e7e7e7;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.home-page-container {
    text-align: center;
}

a {
    color: #000;
}

.default-btn {
    border-radius: 5px;
    padding: 10px 15px;
    margin: 0;
    font-size: 15px;
    color: #fff;
    background-color: #30b0a5;
    border: none;
    text-decoration: none;
    cursor: pointer;
}

.light-btn {
    border-radius: 5px;
    padding: 10px 15px;
    margin: 0;
    font-size: 15px;
    border: none;
    text-decoration: none;
    cursor: pointer;
}
.btn-block {
    width: 100%;
}

.header-back-button svg {
    fill: #000;
}

.hamburger-line {
    width: 100%;
    height: 3px;
    background-color: #000;
}

.left-panel-logo {
    padding: 15.5px;
    box-shadow: rgba(1, 1, 1, 0.15) 8px 5px 13px;
}

.sidebar-links-parent ul {
    list-style: none;
    padding: 0;
    text-align: left;
}

.sidebar-links-parent ul li {
    padding: 1rem 0 1rem 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.sidebar-links-parent ul li:hover {
    background-color: #f3f3f3;
}

.sidebar-links-parent ul a {
    text-decoration: none;
    align-items: center;
    display: flex;
    gap: 10px;
}

.sidebar-links-parent ul a li svg {
    fill: black;
}

.access-denied {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
    gap: 1rem;
    justify-content: center;
}
.access-denied h3 {
    width: 100%;
}
.access-denied * {
    color: #e36969;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
}
.access-denied svg {
    fill: #e36969;
}

.tab-content-x {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: gray;
}
.tab-content-x svg {
    fill: gray;
}

.ant-tabs-nav {
    margin: 0;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .tab-content-x {
    color: #30b0a5;
}
.ant-tabs-tab-active .tab-content-x svg {
    fill: #30b0a5;
}
.ant-tabs .ant-tabs-ink-bar {
    background: #30b0a5;
}

.quiz-container-list {
    padding: 1rem;
}

.upcoming-quiz-title {
    text-align: left;
}

.quiz-timeline-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #f0f0f0;
    padding: 0.5rem;
    text-align: left;
}

.quiz-timeline-item .first-row {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-direction: row;
    padding-block: 0.5rem;
}

.quiz-timeline-item .sec-row {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #f0f0f0;
}

.faq-containr {
    text-align: left;
    padding: 0.5rem;
}

.faq-containr h4 {
    text-decoration: underline;
}

#contact-us-form {
    padding: 0.5rem;
}

#contact-us-form .ant-form-item {
    width: 100%;
}

#contact-us-form .ant-form-item label {
    align-items: center;
    flex-direction: row-reverse;
}
#contact-us-form .ant-form-item label.ant-form-item-required::after {
    display: none;
}
#contact-us-form .ant-form-item label.ant-form-item-required::before {
    margin-left: 0.2rem;
}
.message-content {
    text-align: left;
}

.message-content p {
    margin-top: 0;
}

.feedback-text {
    text-align: left;
    margin: 0;
    font-size: 10px;
}

.feedback-success-text {
    text-align: left;
    margin: 0;
}

.like.active svg {
    fill: #0aa679;
}
.dislike.active svg {
    fill: #cf0b0b;
}

.gold-button {
    background-color: #ffd700 !important; /* Gold color */
    border: 1px solid #daa520 !important; /* Darker gold for border */
    color: #453606 !important; /* White text color */

    &:hover {
        background-color: #ffc700 !important; /* Slightly lighter gold on hover */
        border-color: #c9a017 !important; /* Slightly darker border on hover */
        color: #453606 !important; /* Keep white text */
    }

    &:focus {
        background-color: #ffc700 !important; /* Match hover color on focus */
        border-color: #c9a017 !important; /* Same as hover border color */
    }

    &:active {
        background-color: #ffa500 !important; /* Darker gold on click */
        border-color: #c08400 !important; /* Darker border on click */
    }
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.ml-auto {
    margin-left: auto;
}

.text-center {
    text-align: center;
}

.cupoints-container {
    .cupoints-amount {
        font-size: 0.75rem;
        color: rgb(155 155 155 / 85%);
    }
}

button .cupoints-container .cupoints-amount {
    color: white !important;
}

.cupoint-section {
    display: flex;
    flex-direction: row;

    .promo-apply-label {
        font-size: 0.6rem;
        background-color: #e9d171;
        color: #706539;
        border: thin solid #8b7d48;
        padding: 2px 8px;
        border-radius: 6px;
        text-align: center;
    }
}
