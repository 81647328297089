.quiz-field {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: left;
}
.question-text {
    text-align: left;
    margin-bottom: 10px;
}

.question-option {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.question-option:hover,
.question-option.selected {
    background-color: #64b1a9;
    color: white;
}

.question-option.selected label {
    color: white;
}

.question-option input[type="radio"] {
    display: none;
}

.question-option label {
    display: inline-block;
    padding: 0.5rem;
}

.answer-tip {
    text-align: left;
    padding: 0.5rem;
}

.answer-tip.success {
    background-color: green;
    color: white;
}

.answer-tip.error {
    background-color: red;
    color: white;
}

.option-image {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    height: 50%;
    width: 50%;
}
