#home-page-heading > h1 {
    font-family: "Inter", sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    margin: 25px 0 15px;
}

.home-page-landing {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    padding: 0;
}

.home-category-section {
    width: 100%;
    padding: 3vh 7vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
}

.home-page-landing-image {
    width: 100%;
}

#home-page-heading > h1 .multi-sensory {
    font-weight: bold;
    text-decoration: underline;
    background: linear-gradient(to right, #30b0a5, #433bb1);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    cursor: pointer;
}

#home-page-heading > h1 .multi-sensory::after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 2px;
    background: linear-gradient(to right, #30b0a5, #433bb1);
}

.home-page-landing > div {
    padding: 1rem;
}

.home-page-landing > div:nth-child(odd) {
    background-color: #f0f2f5;
}

#home-page-heading > p {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
}

#home-page-landing-btns {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

#home-page-landing-btns a {
    text-decoration: none;
    width: 100%;
}

#home-page-explore-btn,
.explore-btn {
    display: inline-block;
    background: linear-gradient(90deg, #30b0a5 21.17%, #4339b1 100%);
    border-radius: 5px;
    padding: 10px 15px;
    margin: 0px 0px 0px 0px;
    font-size: 15px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    color: white;
}

.explore-btn:hover {
    color: rgb(219, 219, 219);
}

#home-page-request-demo-btn,
#be-an-author-btn {
    background-color: rgb(74, 106, 142);
    border-radius: 5px;
    padding: 10px 15px;
    margin: 0px;
    font-size: 15px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    color: rgb(255, 255, 255);
}

.done-img,
.watch-img,
.analyze-img {
    width: 100%;
}

#why-cubits-works {
    text-align: center;
}

#why-cubits-works > h2 {
    font-size: 32px;
    font-family: "Inter", sans-serif;
    font-weight: bold;
}

#why-cubits-works > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    margin: 0 auto;
}

#why-cubits-works > div > div {
    background-color: #f0f2f5;
}

#why-cubits-works-watch {
    margin-top: 20px;
    background-color: #353535;
    padding: 20px 30px;
}

#why-cubits-works-watch > p {
    color: white;
}

#why-cubits-works-interact {
    margin-top: 10px;
    background-color: #7b9ea8;
    padding: 20px 30px;
}

#why-cubits-works-interact > p {
    color: white;
}

#why-cubits-works-analyze {
    margin-top: 10px;
    background-color: #31467c;
    padding: 20px 30px;
}

#why-cubits-works-analyze > p {
    color: white;
}

#why-cubits-works.contain {
    padding: 100px calc((100% - 1200px) / 2);
}

#home-instructors-section-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#home-instructors-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px;
    gap: 50px;
}

#home-instructors-section > div > img {
    width: 100%;
    margin: 0 auto;
}

#home-instructors-section-text {
    width: 100%;
}

#home-instructors-section-blockquote {
    width: 300px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

#home-learners-section > h2 {
    font-size: 32px;
    font-family: "Inter", sans-serif;
    font-weight: bold;
}

#home-instructors-section-text > h2 {
    font-size: 32px;
    font-family: "Inter", sans-serif;
    font-weight: bold;
}

#home-instructors-section-text > p {
    font-family: "Inter", sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
}

#home-learners-section-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#home-learners-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#home-learners-section-text {
    text-align: center;
    width: 100%;
}

#home-learners-section-text > p {
    font-family: "Inter", sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
}

#home-learners-section-visual > img {
    max-width: 100%;
}
