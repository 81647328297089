#stripe-container {
    width: 100%;
}

.payButtonSection {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.stripe-card-error {
    box-shadow: 0 4px 8px 0 rgba(255, 0, 0, 0.63);
}
.stripe-card-input {
    background: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 5px;
}

.card-error {
    font-size: 15px;
    font-weight: 500;
    color: #ff4f4f;
}

#stripe-pay-now {
    width: 100%;
    background: #556cd6;
    border-color: #556cd6;
}

#payment-form {
    align-items: inherit;
}

.powerByStripeContainer {
    display: flex;
    align-items: center;
}

.radioButtonVerticalPoints label {
    display: flex;
    flex-direction: row;
}

.radioButtonVerticalPoints label span {
    text-align: left;
}