.btn-container {
    display: list;
    position: relative;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    align-items: center;
    justify-content: center;
}

.moduleName {
    display: flex;
    text-align: left;
    color: black;
    width: 100%;
    height: 100%;
    background: #f9f9f9;
    border: none;
    padding: 0.5rem;
}

.img-container {
    position: relative;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr);
}

.img-container.open {
    padding: 0.5rem;
}

.img-container a {
    text-decoration: none;
}

.btn-img {
    max-width: 100%;
    max-height: 100%;
    margin-right: 10px;
}

.video-title {
    padding: 0%;
    margin: 0%;
    // font-family: Arial, sans-serif;
    font-size: 9pt;
    color: black;
    font-weight: normal;
}

.disabled-link {
    pointer-events: none;
}

.lock-wrapper {
    background-color: #dddddd91;
    width: 100%;
    height: 100%;
}

.lock-wrapper svg {
    fill: #ffa000;
    padding: 5px;
}
