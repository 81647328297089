.titleDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.search-bar {
    position: relative;
}

.collection-name {
    font-weight: bold;
    // font-family: "Arial";
    font-size: 1rem;
    line-height: 1;
    text-align: center;
    color: #000000;
    padding: 0.5rem;
    margin: 0;
}

.collection-author {
    font-size: 0.75rem;
    line-height: 1.2;
}

.title {
    // font-family: "Arial";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #444343;
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* Fallback for older browsers */
    word-break: break-word;
    /* Fallback for older browsers */
    max-width: 100%;
    /* Set a maximum width to prevent overflow */
}
