.video-titleDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.video-title {
    // font-family: "Arial";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #444343;
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* Fallback for older browsers */
    word-break: break-word;
    /* Fallback for older browsers */
    max-width: 100%;
    /* Set a maximum width to prevent overflow */
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.headerSlider {
    position: relative;
    left: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerSlider p {
    margin: 0;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.headerSlider {
    left: 15vh;
    position: relative;
    display: flex;
    /* align-items: center;
  justify-content: center; */
}

.headerSlider input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid green;
    margin-right: 10px;
    outline: none;
    position: relative;
    top: 2px;
}

label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-right: 10%;
}

.headerSlider input[type="radio"]:checked:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: green;
    position: absolute;
    top: 1px;
    left: 1px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.future-question {
    display: flex;
    justify-content: center; /* Add this line to horizontally center the button */
}

.video-player-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.video-player-responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 0px;
}
