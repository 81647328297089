.parent-container {
    text-align: left;
    overflow: auto;
    text-align: justify;
    padding: 0 0.75rem;
}

.searchParent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sentence {
    text-align: left;
    margin: 0;
    padding: 0;
    /* allow word wrapping */
    overflow-wrap: break-word;
}

.start-paragraph::before {
    content: "";
    /* Empty content, acts as a line break */
    display: block;
    margin-bottom: 1em;
}

.sentence:hover {
    color: green;
    /* changes the background color of each span on hover */
}

.sentence:active {
    color: green;
    /* changes the background color of each span on hover */
}

.match {
    color: green;
}
