.mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f2f5;
    height: 56px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 100vw;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}

.fixed-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.fixed-search-bar {
    position: fixed;
    top: 56px;
    left: 0;
    width: 100vw;
    background-color: white;
    padding: 8px 16px;
    z-index: 999;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;

    .search-input {
        width: 100%;
        padding: 8px;
    }
}

.side-bar-menu {
    height: 100%;
    border-right: 0;
}

.left-section {
    display: flex;
    align-items: center;
    gap: 8px;
}

.mobile-header-title {
    display: block;
}

.logo img {
    display: block;
}

.right-section {
    display: flex;
    align-items: center;
}

.back-btn {
    font-size: 18px;
    display: flex;
    align-items: center;

    & > span {
        width: 100%;
    }

    &.hide {
        display: none;
    }
}
