.floating-button-container {
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: left;
    z-index: 999;
    padding: 0 12px;
}

.floating-button-list {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    gap: 4px;

    &::-webkit-scrollbar {
        display: none;
    }

    button {
        flex-shrink: 0;
    }
}
