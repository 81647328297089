.wallet-page-wrapper {
    text-align: left;
    padding: 0.5rem;

    .transaction-table {
        table {
            font-size: 12px;
            thead {
                th {
                    padding: 6px !important;
                }
            }
            tbody {
                td {
                    padding: 6px !important;
                }
            }
        }
    }
}
