.course-previewer {
    position: relative;
    .play-intro-btn {
        position: absolute;
        right: 5px;
        bottom: 5px;
        display: flex;
        align-items: center;
        background: #444343d6;
        color: white;
        box-shadow: 0px 0px 6px 0px #ffffff;
        border-color: #6f6f6f;

        > span {
            color: #ffffff;
        }
    }

    .course-previewer-video-previewer {
        position: absolute;
        top: 0px;
        height: 100%;
        width: 100%;
        background-color: black;
        display: flex;
        align-items: center;

        & > div {
            width: 100%;
            height: 100%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    // .vimeo-video-wrapper {
    //     height: 100%;
    // }

    // .vimeo-video-wrapper iframe {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    // }

    .course-previewer-thumbnail,
    .course-previewer-video-previewer {
        transition: opacity 300ms linear 100ms;
    }
}
